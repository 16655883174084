
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from "vue"
import store from '@/store/index'
import Urls from '@/core/helpers/mixin/urls'
import KTMenu from "@/view/layout/header/Menu.vue"
import KTBrand from "@/view/layout/brand/Brand.vue"
import bootstrap from "bootstrap/dist/js/bootstrap.min.js"

export default defineComponent({
  name: "MenuModal",
  components: {
    KTMenu,
    KTBrand
  },
  mixins: [Urls],
  setup() {
    const myModal = ref<null | bootstrap.Modal>(null)

    const userLoggedInFlag = computed(() => {
      return store.state['AuthModule'].isLoggedIn ? true : false;
    })

    const onLinkClick = () => {
      if (myModal.value) {
        myModal.value.hide()
      }
    }

    return {
      onLinkClick,
      userLoggedInFlag,
    }
  },
});
