
import {
  computed,
  onMounted,
  defineComponent,
} from 'vue'
import router from '@/router'
import {
  headerType,
  asideEnabled,
  getRouteSlug,
  isButtonBack,
  currentRoute,
  createNewPageEnabled,
  headerTypeWithMenu,
  getCurrentRouteName,
} from '@/core/helpers/header'
import Dropdown1 from '@/view/content/dropdown/Dropdown1.vue'
import { StickyComponent } from '@/assets/ts/components/_StickyComponent'
import AgroBreadcrumbs from '@/core/components/AgroBreadcrumbs.vue'
import Urls from '@/core/helpers/mixin/urls'

export default defineComponent({
  name: 'Header',
  props: {
    title: String,
  },
  components: {
    Dropdown1,
    AgroBreadcrumbs,
  },
  mixins: [Urls],
  setup() {
    const name = getCurrentRouteName.value

    /**
     * Creates the page name for the back button.
     * @returns {String}.
     */
    const backPageName = computed(() => {
      return String(router.currentRoute.value.matched[1].name) + '-list'
    })

    /**
     * Creates create the page name for the routing.
     * @returns {String}.
     */
    const createPageName = computed(() => {
      return String(router.currentRoute.value.matched[1].name) + '-create'
    })

    /**
     * Detects the login page for showing breadcrumbs.
     * @returns {Boolean}.
     */
    const showBreadcrumbs = computed(() => {
      return router.currentRoute.value.name !== 'login-page' ? true : false
    })

    onMounted(() => {
      StickyComponent.bootstrap()
    })

    return {
      name,
      currentRoute,
      headerType,
      asideEnabled,
      getRouteSlug,
      isButtonBack,
      createPageName,
      showBreadcrumbs,
      backPageName,
      createNewPageEnabled,
      headerTypeWithMenu,
      getCurrentRouteName,
    }
  },
})
