import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = { class: "d-flex flex-column flex-column-fluid" }
const _hoisted_3 = {
  class: "content fs-6 d-flex flex-column-fluid container-fluid pt-0",
  id: "kt_content"
}
const _hoisted_4 = { class: "container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_Dashboards = _resolveComponent("Dashboards")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTSidebar = _resolveComponent("KTSidebar")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTMenuModal = _resolveComponent("KTMenuModal")!
  const _component_KTSearchModal = _resolveComponent("KTSearchModal")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTLoader),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.displayAside)
        ? (_openBlock(), _createBlock(_component_KTAside, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "kt_wrapper",
        class: _normalizeClass(["wrapper d-flex flex-column flex-row-fluid page-width", {
        'page-width_full': _ctx.sidebarStateExpand
      }])
      }, [
        _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.displayToolbar)
            ? (_openBlock(), _createBlock(_component_KTToolbar, {
                key: 0,
                breadcrumbs: _ctx.breadcrumbs,
                title: _ctx.pageTitle,
                section: _ctx.pageSection
              }, null, 8, ["breadcrumbs", "title", "section"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Dashboards)
            ])
          ])
        ]),
        _createVNode(_component_KTFooter)
      ], 2)
    ]),
    (_ctx.displaySidebar)
      ? (_openBlock(), _createBlock(_component_KTSidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTMenuModal),
    _createVNode(_component_KTSearchModal),
    _createVNode(_component_KTCreateApp),
    _createVNode(_component_KTDrawerMessenger)
  ], 64))
}