import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_scrolltop",
  ref: "kt_scrolltop",
  class: "scrolltop",
  "data-kt-scrolltop": "true"
}
const _hoisted_2 = { class: "svg-icon" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        alt: "Скролл наверх",
        src: _ctx.setHost('media/icons/duotone/Navigation/Up-2.svg')
      }, null, 8, _hoisted_3)
    ])
  ], 512))
}