
import { defineComponent, onMounted, ref } from "vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default defineComponent({
  name: "SearchModal",
  components: {
    ListWidget1
  },
  setup() {
    const modalEl = ref<bootstrap.Modal | null>(null);
    const searchModalRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      modalEl.value = new bootstrap.Modal(searchModalRef.value);
    });

    onMounted(() => {
      searchModalRef.value?.querySelectorAll("a.modal-link").forEach(item => {
        item.addEventListener("click", () => {
          modalEl.value.hide();
        });
      });
    });

    return {
      searchModalRef
    };
  }
});
