
import { defineComponent, onMounted, ref } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useField, useForm } from "vee-validate";
import * as Yup from "yup";

interface KTApp {
  appName: string;
  appType: string;
  framework: string;
  dbName: string;
  dbType: string;
  storage: string;
}

export default defineComponent({
  name: "create-app",
  components: {},
  setup() {
    // Initialize Stepper
    const _stepperObj = ref<StepperComponent | null>(null);

    const createAppRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAppRef.value as HTMLElement
      );
    });

    // Create a form validation schema
    const createAppSchema = {
      appName: Yup.string()
        .required()
        .label("App name"),
      dbName: Yup.string()
        .required()
        .label("Database name")
    };

    // Create a form context with the validation schema
    useForm({
      validationSchema: createAppSchema
    });

    const {
      value: appName,
      errorMessage: appNameError,
      validate: validateAppName
    } = useField("appName");
    const {
      value: dbName,
      errorMessage: dbNameError,
      validate: validateDbName
    } = useField("dbName");

    //form data
    const data = ref<KTApp>({
      appName: "",
      appType: "Quick Online Courses",
      framework: "HTML5",
      dbName: "",
      dbType: "MySQL",
      storage: "Basic Server"
    });

    const handleStep = (validate, stepper) => {
      validate().then(result => {
        if (result.valid) {
          stepper.value.goNext();
        } else {
          Swal.fire({
            text:
              "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        }
      });
    };

    const nextStep = async () => {
      if (!_stepperObj.value) {
        return;
      }

      if (_stepperObj.value?.getCurrentStepIndex() === 1) {
        data.value.appName = appName.value as string;
        await handleStep(validateAppName, _stepperObj);
        return;
      } else {
        if (_stepperObj.value.getCurrentStepIndex() === 3) {
          data.value.dbName = dbName.value as string;
          await handleStep(validateDbName, _stepperObj);
          return;
        }
      }
      _stepperObj.value.goNext();
    };

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary"
        }
      }).then(() => {
        window.location.reload();
      });
    };

    return {
      formSubmit,
      appName,
      appNameError,
      dbName,
      dbNameError,
      data,
      nextStep,
      previousStep,
      createAppRef
    };
  }
});
