
import { defineComponent } from "vue";
import { toAbsoluteUrl } from "@/core/helpers/assets";

export default defineComponent({
  name: "Brand",
  components: {},
  setup() {
    const siteLogo = () => {
      return toAbsoluteUrl("media/logos/logo-vector.svg");
    };

    return {
      siteLogo
    };
  }
});
