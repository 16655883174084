
import { computed, defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {
  displaySidebar,
  displayAside,
  displayToolbar
} from "@/core/helpers/config";
import { loading } from "@/core/helpers/loader";
import { Actions } from "@/store/enums/store.enums";
import LayoutService from "@/core/services/LayoutService";
import KTSidebar from "@/view/layout/sidebar/Sidebar.vue";
import KTLoader from "@/view/content/Loader.vue";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop.vue";
import KTMenuModal from "@/view/layout/extras/MenuModal.vue";
import KTSearchModal from "@/view/layout/extras/SearchModal.vue";
import KTToolbar from "@/view/layout/toolbar/Toolbar.vue";
import KTCreateApp from "@/view/content/modals/CreateAppModal.vue";
import KTDrawerMessenger from "@/view/content/extras/DrawerMessenger.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import Dashboards from "@/view/pages/dashboards/Dashboards.vue";



export default defineComponent({
  name: "layout",
  components: {
    KTAside,
    KTSidebar,
    KTLoader,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTMenuModal,
    KTSearchModal,
    KTToolbar,
    KTCreateApp,
    KTDrawerMessenger,
    Dashboards,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const breadcrumbs = computed(() => {
      return store.getters.getBreadcrumbs;
    });

    const pageSection = computed(() => {
      return store.getters.pageSection;
    });

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    onMounted(() => {
      LayoutService.initCurrentRouteLayout();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 1000);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        LayoutService.initCurrentRouteLayout();
      }
    );

    watch(
      () => route.meta.asideEnabled,
      (from, to) => {
        if (!to) {
          loading(500);
        }
      }
    );

    const sidebarStateExpand = computed(() => {
      return store.state['ConfigModule'].sidebarStateExpand;
    });

    return {
      breadcrumbs,
      pageTitle,
      displaySidebar,
      displayAside,
      displayToolbar,
      pageSection,
      store,
      sidebarStateExpand,
    };
  }
});
