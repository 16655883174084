
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";

export default defineComponent({
  name: "Sidebar",
  components: {},
  setup() {
    const route = useRoute();

    onMounted(() => {
      DrawerComponent.reinitialization();
    });

    const sidebarType = () => {
      return route.meta.sidebar
        ? "kt-sidebar-" + route.meta.sidebar
        : "kt-sidebar-general";
    };

    const isShop = () => {
      return route.meta.sidebar === "shop";
    };

    return {
      sidebarType,
      isShop
    };
  }
});
