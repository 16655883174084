
import { defineComponent, onMounted, ref } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
/*
TIP: To start with clean menu update the config path to @/core/config/MainMenuCleanConfig".
 */
import { MainMenuConfig } from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const myModal = ref<null | bootstrap.Modal>(null);

    onMounted(() => {
      myModal.value = new bootstrap.Modal(
        document.getElementById("kt_mega_menu_modal")
      );
    });

    const onLinkClick = () => {
      if (myModal.value) {
        myModal.value.hide();
      }
    };

    return {
      onLinkClick,
      MainMenuConfig,
    };
  }
});
