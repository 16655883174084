const MainMenuConfig = [
  {
    heading: 'Дашборд',
    active: true,
    pages: [
      {
        heading: 'Новости',
        active: true,
        route: '/news',
        icon: 'newspaper',
      },
      {
        heading: 'Объявления',
        active: true,
        route: '/ads',
        icon: 'postcard',
      },
      {
        heading: 'Пользователи',
        active: true,
        route: '/users',
        icon: 'people',
      },
      {
        heading: 'Тикеты',
        active: false,
        route: '/tickets',
        icon: '',
      },
      {
        heading: 'Компании',
        active: false,
        route: '/companies',
        icon: '',
      },
      {
        heading: 'Статистика',
        active: false,
        route: '/statistics',
        icon: '',
      },
    ]
  },
  {
    heading: 'Сервисы',
    active: false,
    pages: [
      {
        heading: 'Email рассылки',
        active: false,
        route: '/newsletter'
      },
      {
        heading: 'Рассылки участникам',
        active: false,
        route: '/newsletter-users'
      },
      {
        heading: 'Тестирование кэширования',
        active: false,
        route: '/caching-testing'
      }
    ]
  },
  {
    heading: 'Биллинг',
    active: false,
    pages: [
      {
        heading: 'Печать актов',
        active: false,
        route: '/acts-printing'
      }
    ]
  },
  {
    heading: 'Справочники',
    active: false,
    pages: [
      {
        heading: 'Города',
        active: false,
        route: '/cities'
      },
      {
        heading: 'Источники статей',
        active: false,
        route: '/articles-sources'
      }
    ]
  },
  {
    heading: 'Медиа',
    active: false,
    pages: [
      {
        heading: 'Видео',
        active: false,
        route: '/video'
      }
    ]
  },
  {
    heading: 'Настройки',
    active: false,
    pages: [
      {
        heading: 'Логи',
        active: false,
        route: '/logs'
      },
      {
        heading: 'Настройка рубрик',
        active: false,
        route: '/category-setting'
      }
    ]
  },
  {
    heading: 'Общие',
    active: false,
    pages: [
      {
        heading: 'Профиль',
        active: false,
        route: '/profile'
      },
      {
        heading: 'Настройки',
        active: false,
        route: '/settings'
      },
      {
        heading: 'Выйти',
        active: false,
        route: '/logout'
      }
    ]
  }
]

export { MainMenuConfig }
