import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column sidebar-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "kt_sidebar",
    class: _normalizeClass(["sidebar", [_ctx.isShop() && 'bg-white', !_ctx.isShop() && 'bg-info']]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "sidebar",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '350px': '300px'}",
    "data-kt-drawer-direction": "end",
    "data-kt-drawer-toggle": "#kt_sidebar_toggler"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sidebarType())))
    ])
  ], 2))
}