
import { defineComponent, onMounted } from "vue";
import { ScrollTopComponent } from "@/assets/ts/components/_ScrollTopComponent";
import Urls from '@/core/helpers/mixin/urls';

export default defineComponent({
  name: "KTScrollTop",
  components: {},
  mixins: [Urls],
  setup() {
    onMounted(() => {
      ScrollTopComponent.reinitialization();
    });
  }
});
