
import { defineComponent, computed } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AgroBreadcrumbs',
  setup() {
    const store = useStore();

    // Detection pages for showing the module.
    const showBreadcrumbs = computed(() => {
      return router.currentRoute.value.name !== 'login-page' ? true : false;
    });

    const breadcrumbs = computed(() => {
      return store.getters.getBreadcrumbs;
    });

    return {
      showBreadcrumbs,
      breadcrumbs,
    };
  },
});
