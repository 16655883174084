
import { computed, defineComponent, onMounted, onUpdated } from 'vue';
import {
  displayAsidePrimary,
  displayAsideSecondary,
} from '@/core/helpers/config';
import { asideMenu } from '@/core/helpers/aside';
import { DrawerComponent } from '@/assets/ts/components/_DrawerOptions';
import { ToggleComponent } from '@/assets/ts/components/_ToggleComponent';
import KTAsideMenu from '@/view/layout/aside/Menu.vue';
import Dropdown3 from '@/view/content/dropdown/Dropdown3.vue';
import Urls from '@/core/helpers/mixin/urls';
import { useStore } from "vuex";
import { Actions } from "@/store/enums/store.enums";

export default defineComponent({
  name: 'kt-aside',
  components: {
    KTAsideMenu,
    Dropdown3,
  },
  mixins: [Urls],
  setup() {
    const store = useStore();

    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.bootstrap();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    const sidebarStateExpand = computed(() => {
      return store.state['ConfigModule'].sidebarStateExpand;
    });

    const toggleSidebar = () => {
      store.dispatch(Actions.SET_SIDEBAR_STATE, !sidebarStateExpand.value);
      console.log('> toggleSidebar', sidebarStateExpand.value);
    };

    return {
      asideMenu,
      toggleSidebar,
      sidebarStateExpand,
      displayAsidePrimary,
      displayAsideSecondary,
    };
  },
});
