<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-300px"
    data-kt-menu="true"
  >
    <div
      v-if="userLoggedInFlag"
      class="bg-info menu-content fw-bold d-flex align-items-center bgi-no-repeat bgi-position-y-top rounded-top"
      style="background-image:url('/media/misc/dropdown-header-bg.jpg')"
    >
      <div class="symbol symbol-45px mx-5 py-5">
        <span class="symbol-label bg-primary align-items-end">
          <img
            alt="Аватар"
            :src="setHost('media/svg/avatars/001-boy.svg')"
            class="mh-35px"
          />
        </span>
      </div>
      <div class="">
        <span class="text-white fw-bolder fs-4">
          Привет, {{ adminFullName }}
        </span>
        <span class="text-white fw-bold fs-7 d-block">
          Администратор
        </span>
      </div>
    </div>

    <!--begin::Row-->
    <div class="row row-cols-2 g-0">
      <router-link
        v-if="userLoggedInFlag"
        :to="{
          name: 'my-profile',
        }"
        class="border-bottom border-end text-center py-10 btn btn-active-color-primary rounded-0"
      >
        <span class="svg-icon svg-icon-3x me-n1">
          <img
            alt="Мой профиль"
            :src="setHost('media/icons/duotone/Layout/Layout-4-blocks-2.svg')"
          />
        </span>
        <span class="  fw-bolder fs-6 d-block pt-3">
          Мой профиль
        </span>
      </router-link>

      <router-link
        v-if="userLoggedInFlag"
        :to="{
          name: 'my-settings',
        }"
        class="col border-bottom text-center py-10 btn btn-active-color-primary rounded-0"
      >
        <span class="svg-icon svg-icon-3x me-n1">
          <img
            alt="Настройки"
            :src="setHost('media/icons/duotone/General/Settings-1.svg')"
          />
        </span>
        <span class="fw-bolder fs-6 d-block pt-3">
          Настройки
        </span>
      </router-link>

      <router-link
        :to="{
          name: 'login-page',
        }"
        class="col text-center py-10 btn btn-active-color-primary rounded-0"
        @click="userLoggedInFlag && loggedOut()"
      >
        <span class="svg-icon svg-icon-3x me-n1">
          <img
            :alt="loginTitle"
            :src="setHost('media/icons/duotone/Navigation/Sign-in.svg')"
          />
        </span>
        <span class="fw-bolder fs-6 d-block pt-3">
          {{ loginTitle }}
        </span>
      </router-link>
    </div>
    <!--end::Row-->
  </div>
  <!--end::Menu-->
</template>

<script>
import {
  computed,
  onMounted,
  defineComponent,
} from 'vue';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import store from '@/store/index';
import { Actions } from '@/store/enums/store.enums';
import Urls from '@/core/helpers/mixin/urls';
import AuthService from '@/core/services/AuthService'

export default defineComponent({
  name: 'dropdown-1',
  components: {},
  mixins: [Urls],
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const adminFullName = computed(() => {
      if (store.state['AdminModule'].adminData && store.state['AdminModule'].adminData.first_name) {
        return store.state['AdminModule'].adminData.first_name + ' ' + store.state['AdminModule'].adminData.last_name
      } else {
        return 'пользователь'
      }
    })

    return {
      adminFullName,
    }
  },
  computed: {
    loginTitle() {
      return store.state['AuthModule'].isLoggedIn ? 'Выйти' : 'Войти';
    },
    userLoggedInFlag() {
      return store.state['AuthModule'].isLoggedIn ? true : false;
    },
  },
  methods: {
    loggedOut() {
      console.log('loggedOut ::');
      store.dispatch(Actions.SET_IS_LOGGED_OUT);
      AuthService.removeTokens()
      this.$message({
        type: 'warning',
        message: 'Вы вышли из системы',
      });
    },
  },
});
</script>
