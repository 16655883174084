import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-lg-6 pr-lg-25" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "font-weight-bolder mb-5 fw-bolder" }
const _hoisted_4 = { class: "menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10" }
const _hoisted_5 = { class: "menu-item" }
const _hoisted_6 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MainMenuConfig, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "col-sm-4"
        }, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(item.heading), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (page, j) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: j,
                to: page.route
              }, {
                default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                  _createElementVNode("li", _hoisted_5, [
                    _createElementVNode("a", {
                      class: _normalizeClass(["menu-link ps-0 py-2 menu-modal-link", [isActive && 'active', isExactActive && 'active']]),
                      href: href,
                      onClick: ($event: any) => {
                      _ctx.onLinkClick();
                      navigate;
                    }
                    }, _toDisplayString(page.heading), 11, _hoisted_6)
                  ])
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}