
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import Urls from '@/core/helpers/mixin/urls';
// import { DrawerComponent } from '@/assets/ts/components/_DrawerOptions'
import {
  DrawerComponent,
} from "@/assets/ts/components"

export default defineComponent({
  name: "Menu2",
  props: {
    menuConfig: Object
  },
  components: {},
  mixins: [Urls],
  setup() {
    const store = useStore();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    const handleClick = () => {
      DrawerComponent.hideAll()
    }

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    const sidebarStateExpand = computed(() => {
      return store.state['ConfigModule'].sidebarStateExpand;
    });

    return {
      handleClick,
      hasActiveChildren,
      sidebarStateExpand,
    };
  }
});
